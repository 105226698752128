
// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
  }

import Headroom from "headroom.js";
import Swiper from 'swiper';
import { Pagination, Autoplay  } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// import "vite/dynamic-import-polyfill";
import MicroModal from 'micromodal';  // es6 module

import "../css/app.css";

MicroModal.init({
    onShow: modal => console.info(`${modal.id} is shown`), // [1]
    onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    // openTrigger: 'data-custom-open', // [3]
    // closeTrigger: 'data-custom-close', // [4]
    openClass: 'is-open', // [5]
    disableScroll: true, // [6]
    disableFocus: false, // [7]
    awaitOpenAnimation: false, // [8]
    awaitCloseAnimation: false, // [9]
    debugMode: true // [10]
  });


// Main HEADROOM Header

var options = {
  // vertical offset in px before element is first unpinned
  offset : 0,
  // or you can specify offset individually for up/down scroll
  offset: {
    up: 0,
    down: 0
},
  // scroll tolerance in px before state changes
  tolerance : 0,
  // or you can specify tolerance individually for up/down scroll
  tolerance : {
      up : 5,
      down : 5
  },
  // css classes to apply
  classes : {
      // when element is initialised
      initial : "headroom",
      // when scrolling up
      pinned : "headroom--pinned",
      // when scrolling down
      unpinned : "headroom--unpinned",
      // when above offset
      top : "headroom--top",
      // when below offset
      notTop : "headroom--not-top",
      // when at bottom of scroll area
      bottom : "headroom--bottom",
      // when not at bottom of scroll area
      notBottom : "headroom--not-bottom",
      // when frozen method has been called
      frozen: "headroom--frozen",
      // multiple classes are also supported with a space-separated list
      pinned: "headroom--pinned"
  },
  // element to listen to scroll events on, defaults to `window`
  // scroller : someElement,
  // callback when pinned, `this` is headroom object
  onPin : function() {},
  // callback when unpinned, `this` is headroom object
  onUnpin : function() {},
  // callback when above offset, `this` is headroom object
  onTop : function() {},
  // callback when below offset, `this` is headroom object
  onNotTop : function() {},
  // callback when at bottom of page, `this` is headroom object
  onBottom : function() {},
  // callback when moving away from bottom of page, `this` is headroom object
  onNotBottom : function() {}
};


// main header

var headerElement = document.querySelector("#header");
var subnavElement = document.querySelector("#subnav-panel");
var headroom  = new Headroom(headerElement, options);


if (headerElement !== null) {
  headroom.init();
}

var openMobileMenu = document.querySelector("#menuOpen");
var mobileMenu = document.querySelector("#mobile-menu-panel");
var closeMobileMenu = document.querySelector("#open-menu");
var menu_panel = document.querySelector("#menu-bg");



// Main HEADROOM Header

var locOptions = {
  // vertical offset in px before element is first unpinned
  offset : 0,
  // or you can specify offset individually for up/down scroll
  offset: {
    up: 0,
    down: 0
},
  // scroll tolerance in px before state changes
  tolerance : 0,
  // or you can specify tolerance individually for up/down scroll
  tolerance : {
      up : 5,
      down : 5
  },
  // css classes to apply
  classes : {
      // when element is initialised
      initial : "headroom",
      // when scrolling up
      pinned : "headroom--pinned",
      // when scrolling down
      unpinned : "headroom--unpinned",
      // when above offset
      top : "headroom--top",
      // when below offset
      notTop : "headroom--not-top",
      // when at bottom of scroll area
      bottom : "headroom--bottom",
      // when not at bottom of scroll area
      notBottom : "headroom--not-bottom",
      // when frozen method has been called
      frozen: "headroom--frozen",
      // multiple classes are also supported with a space-separated list
      pinned: "headroom--pinned"
  },
  // element to listen to scroll events on, defaults to `window`
  // scroller : someElement,
  // callback when pinned, `this` is headroom object
  onPin : function() {},
  // callback when unpinned, `this` is headroom object
  onUnpin : function() {},
  // callback when above offset, `this` is headroom object
  onTop : function() {},
  // callback when below offset, `this` is headroom object
  onNotTop : function() {},
  // callback when at bottom of page, `this` is headroom object
  onBottom : function() {},
  // callback when moving away from bottom of page, `this` is headroom object
  onNotBottom : function() {}
};



// location header

var locHeaderElement = document.querySelector("#locHeader");
var locHeadroom  = new Headroom(locHeaderElement, locOptions);


if (locHeaderElement !== null) {
  locHeadroom.init();
}


// Swiper Gallery



// init Swiper:
const swiper = new Swiper('.swiper', {
    modules: [ Pagination, Autoplay],
    loop: true,
    watchOverflow: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    on: {
      beforeInit() {
        const slides = this.el.querySelectorAll('.swiper-slide');
  
        if (slides) {
          this.params.loop = slides.length > 1;
          this.params.autoplay.enabled = slides.length > 1;
        }
      },
    },
});





// activate main menu BG when children links are hovered

document.addEventListener("DOMContentLoaded", (event) => {
    // const mainLinks = document.querySelectorAll(".main-link");
 
    openMobileMenu.addEventListener("click", (e) => {
        e.stopImmediatePropagation();
        mobileMenu.classList.toggle("hidden");
        console.log("toggle menu");

        setTimeout(function(){
            mobileMenu.classList.toggle("opaque");
        }, 1); 

    });

  });

// activate location menu BG when children links are hovered

// var openLocMobileMenu = document.querySelector("#menuLocOpen");
// var mobileLocMenu = document.querySelector("#locHeader");


//   document.addEventListener("DOMContentLoaded", (event) => {
//     // const mainLinks = document.querySelectorAll(".main-link");
 
//     openLocMobileMenu.addEventListener("click", (e) => {
//         e.stopImmediatePropagation();
//         mobileLocMenu.classList.toggle("inactive");
//         console.log("toggle menu");

//         setTimeout(function(){
//           mobileLocMenu.classList.toggle("active");
//         }, 1); 

//     });

//   });